<script setup>
import { onMounted } from "vue";
import { SET_LOGIN_INFO } from "@/store/mutation-types";
import { getLocalStorageCache } from "@/utility/cache";
import { NAME, TOKEN, ISLOGIN, VERSION, TENANT_ID } from "@/common/cache-key";
import { useStore } from "vuex";
const store = useStore();
// 强制刷新重新存缓存获取数据
const reloadData = () => {
    const name = getLocalStorageCache(NAME);
    const isLogin = getLocalStorageCache(ISLOGIN);
    const token = getLocalStorageCache(TOKEN);
    const version = getLocalStorageCache(VERSION);
    const tenant_id = getLocalStorageCache(TENANT_ID);
    store.commit(SET_LOGIN_INFO, {
        name,
        isLogin,
        token,
        version,
        tenant_id
    });
};
onMounted(() => {
    reloadData();
});

</script>

<template>
    <router-view />
</template>


<style scoped lang="stylus"></style>
