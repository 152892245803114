import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Login.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Home.vue"),
    children: [
      {
        path: "/links",
        name: "DashBoard",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/DashBoard.vue"),
      },
      {
        path: "/linkdetail",
        name: "LinkDetail",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/LinkDetail.vue"),
      },
      {
        path: "/products",
        name: "Item",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Item/index.vue"),
      },
      {
        path: "/products/detail",
        name: "ItemDetail",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Item/detail.vue"),
      },
      {
        path: "/basic",
        name: "Basic",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Item/Basic.vue"),
      },
      {
        path: "/targeting",
        name: "Targeting",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Item/Targeting.vue"),
      },
      {
        path: "/landingPage",
        name: "LandingPage",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/Item/LandingPage.vue"
          ),
      },
      {
        path: "/videoScript",
        name: "VideoScript",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/Item/VideoScript.vue"
          ),
      },
      {
        path: "/videoScriptEdit",
        name: "VideoScriptEdit",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/Item/VideoScriptEdit.vue"
          ),
      },
      {
        path: "/videoScene",
        name: "VideoScene",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Item/VideoScene.vue"),
      },
      {
        path: "/videoClip",
        name: "VideoClip",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Item/VideoClip.vue"),
      },

      {
        path: "/videoTask",
        name: "VideoTask",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Item/VideoTask.vue"),
      },
      {
        path: "/videos",
        name: "Videos",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Item/Videos.vue"),
      },
      {
        path: "/article",
        name: "Article",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Article/index.vue"),
      },
      {
        path: "/article-detail",
        name: "ArticleDetail",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Article/detail.vue"),
      },
      {
        path: "/keyword",
        name: "Keyword",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Keyword/index.vue"),
      },
      {
        path: "/gather",
        name: "Gather",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Keyword/detail.vue"),
      },
      {
        path: "/category",
        name: "Category",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Category/index.vue"),
      },
      {
        path: "/category-detail",
        name: "CategoryDetail",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Category/detail.vue"),
      },
      {
        path: "/category-analysis",
        name: "CategoryAnalysis",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/Category/analysis.vue"
          ),
      },
      {
        path: "/brand",
        name: "Brand",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Brand/index.vue"),
      },
      {
        path: "/scrapers",
        name: "ScraperTask",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/ScraperTask/index.vue"
          ),
      },
      {
        path: "/tool",
        name: "Tool",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Tool.vue"),
      },
      {
        path: "/knowledge-base",
        name: "knowledgeBase",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/knowledgeBase/index.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (from.path != "/products/detail" && from.path != "/products") {
    store.state.keepAlive = 0;
    store.state.filter.pageNo = 1;
    store.state.filter.pageSize = 15;
  }
  next();
});
export default router;
